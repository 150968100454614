import { FilesHelpers } from 'helpers/files';
import { Observable, PureComputed, Subscribable } from 'knockout';
import UserFile from 'models/userFile';
import { JSONWCCAttachment } from './attachment';
import { DownloadableWCCAttachment } from './downloadableAttachment';
import WCCTranscribableAttachment from "./transcribableAttachment";

interface AttachmentFields {
    originalFileName: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    hasThumbnail: Observable<boolean>
    hasHQVersion: Observable<boolean>
}

export interface JSONWCCVideo extends JSONWCCAttachment {
    CDNFileName?: string
    OriginalFileName?: string
    FileExtension?: string
    FileHash?: string
    HQLink?: string
    Crossorigin?: string
    Width?: number
    Height?: number
    Duration?: number
    HQFileLength?: number
    LQFileLength?: number
    HasHQVersion?: boolean
    HasThumbnail?: boolean

    IsWatched?: boolean
    IsIgnored?: boolean

    TranscriptionEnabled?: boolean
    TranscriptStatus?: number
    TranscriptContent?: string
}

export default class WCCVideo extends WCCTranscribableAttachment implements DownloadableWCCAttachment {
    private base: AttachmentFields

    cdnFileName: Observable<string | undefined>
    originalFileName: Observable<string | undefined>
    fileExtension: Observable<string | undefined>
    fileHash: Observable<string | undefined>
    hqLink: Observable<string | undefined>
    crossorigin: Observable<string | undefined>
    duration: Observable<number | undefined>
    hqFileLength: Observable<number | undefined>
    lqFileLength: Observable<number | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    hasHQVersion: Observable<boolean>
    hasThumbnail: Observable<boolean>

    oIsWatched: Observable<boolean>
    oIsIgnored: Observable<boolean>

    originalFile: PureComputed<UserFile | undefined>
    thumbnail: PureComputed<UserFile | undefined>
    lqFile: PureComputed<UserFile | undefined>
    hqFile: PureComputed<UserFile | undefined>

    thumbnailUrl: Subscribable<string | undefined>
    lqUrl: Subscribable<string | undefined>
    lqUrlRaw: Subscribable<string | undefined>
    hqUrl: Subscribable<string | undefined>
    token: Subscribable<string | undefined>

    constructor(data?: JSONWCCVideo) {
        super(data);

        this.cdnFileName = this.createField(data, 'CDNFileName');
        this.fileHash = this.createField(data, 'FileHash');
        this.hqLink = this.createField(data, 'HQLink');
        this.crossorigin = this.createField(data, 'Crossorigin');
        this.duration = this.createField(data, 'Duration');
        this.hqFileLength = this.createField(data, 'HQFileLength');
        this.lqFileLength = this.createField(data, 'LQFileLength');

        this.oIsWatched = this.createField(data, 'IsWatched', false);
        this.oIsIgnored = this.createField(data, 'IsIgnored', false);
        
        this.base = {
            originalFileName: this.createField(data, 'OriginalFileName'),
            fileExtension: this.createField(data, 'FileExtension'),
            width: this.createField(data, 'Width'),
            height: this.createField(data, 'Height'),
            hasThumbnail: this.createField(data, 'HasThumbnail', false as boolean),
            hasHQVersion: this.createField(data, 'HasHQVersion', false as boolean),
        }

        const isOldAttachment = ko.pureComputed(() => this.link() != undefined);

        const oldThumbnailFile = ko.pureComputed(() => {
            if (isOldAttachment() && this.base.hasThumbnail()) {
                const link = this.getOldThumbnailLink();

                if (link != undefined)
                    return this.getUserFile(link, enums.UserFileTypes.VideoThumbnail.value);
            }
        }).extend({ deferred: true });

        var oldLQFile = ko.pureComputed(() => {
            if (isOldAttachment())
                return this.getUserFile(this.link(), enums.UserFileTypes.LQVideo.value);
        }).extend({ deferred: true });

        var oldHQFile = ko.pureComputed(() => {
            if (isOldAttachment() && this.base.hasHQVersion())
                return this.getUserFile(this.link()?.replace('_lq.mp4', '_hq.mp4'), enums.UserFileTypes.HQVideo.value);
        }).extend({ deferred: true });

        const pendingVideoFile = this.getObservablePendingFile(enums.UserFileTypes.PendingVideo.value);
        const pendingVideoFileRaw = this.getObservableFile(enums.UserFileTypes.PendingVideo.value);
        const originalFile = this.getObservableFile(enums.UserFileTypes.OriginalVideo.value);
        const lqFile = this.getObservableFile(enums.UserFileTypes.LQVideo.value, [originalFile, oldLQFile, pendingVideoFile]);
        const lqFileRaw = this.getObservableFile(enums.UserFileTypes.LQVideo.value, [originalFile, oldLQFile, pendingVideoFileRaw]);
        const hqFile = this.getObservableFile(enums.UserFileTypes.HQVideo.value, [originalFile, oldHQFile]);
        const lqFileOrDefaults = ko.pureComputed(() => lqFile() ?? originalFile() ?? hqFile());
        const lqFileRawOrDefaults = ko.pureComputed(() => lqFileRaw() ?? originalFile() ?? hqFile());
        const hqFileOrDefaults = ko.pureComputed(() => hqFile() ?? originalFile() ?? lqFileOrDefaults());
        const pendingThumbnailFile = this.getObservablePendingFile(enums.UserFileTypes.PendingVideoThumbnail.value);
        const thumbnailFile = this.getObservableFile(enums.UserFileTypes.VideoThumbnail.value, [oldThumbnailFile, pendingThumbnailFile]);

        this.originalFile = originalFile;
        this.thumbnail = thumbnailFile;
        this.lqFile = lqFileOrDefaults;
        this.hqFile = hqFile;

        this.thumbnailUrl = thumbnailFile.pluck('link');
        this.lqUrl = lqFileOrDefaults.pluck('link');
        this.lqUrlRaw = lqFileRawOrDefaults.pluck('link');
        this.hqUrl = hqFile.pluck('link');
        this.token = lqFileOrDefaults.pluck('token');

        this.originalFileName = ko.pureComputed({
            read: () => hqFileOrDefaults()?.name(),
            write: this.base.originalFileName
        });

        this.fileExtension = ko.pureComputed({
            read: () => hqFileOrDefaults()?.extension(),
            write: this.base.fileExtension
        });

        this.width = ko.pureComputed({
            read: () => hqFile()?.width(),
            write: this.base.width
        });

        this.height = ko.pureComputed({
            read: () => hqFile()?.height(),
            write: this.base.height
        });

        this.hasThumbnail = ko.pureComputed({
            read: () => this.thumbnailUrl() != undefined,
            write: this.base.hasThumbnail
        });

        this.hasHQVersion = ko.pureComputed({
            read: () => this.hqUrl() != undefined,
            write: this.base.hasHQVersion
        });
    }

    protected override getViewTitle() {
        return this.title() || this.originalFileName() || '';
    }

    private getUserFile(link: string | undefined, type: number) {
        return new UserFile({
            UserFileId: this.userFileId(),
            Name: this.base.originalFileName(),
            Extension: this.base.fileExtension(),
            Link: link,
            Width: this.base.width(),
            Height: this.base.height(),
            Type: type,
            CreateDate: this.createDate()
        });
    }

    private getOldThumbnailLink() {
        const link = this.link() ?? '';

        if (this.base.hasThumbnail() && link != undefined) {
            if (link.indexOf("_lq.mp4") > 0)
                return link.replace("_lq.mp4", '_thumb.jpg');
            else
                return link + '.jpg';
        }
    }

    public async download() {
        return await FilesHelpers.saveAs(await FilesHelpers.loadAsBlob(this.hqUrl() ?? this.lqUrl() ?? ''), this.originalFileName() ?? 'wcs_video.mp4');
    }
}